import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import {
    validImageFormats,
    checkImageFile,
    checkJsonFile,
    fileJsonType,
} from '../utils/fileUtils';
const singleUpload = async ({
    params,
    resource,
    fileKey,
    resultKey,
    idKey,
}) => {
    console.log('singleUpload', {
        params,
        resource,
        fileKey,
        resultKey,
        idKey,
    });
    const file = params.data[fileKey].rawFile;
    if (validImageFormats.includes(file.type)) {
        if (file.type === fileJsonType) {
            const jsonError = await checkJsonFile(file);
            if (jsonError) {
                console.error(jsonError);
                return;
            }
        } else {
            const imageError = await checkImageFile(file);
            if (imageError) {
                console.error(imageError);
                return;
            }
        }
    }
    const itemKey =
        idKey && params.data[idKey] ? params.data[idKey] : new Date().getTime();
    if (!file) return;
    const key = `${resource}/${itemKey}${`_${uuidv4()}.${file.name
        .split('.')
        .pop()}`}`;
    const contentType = params.data.pictures
        ? params.data.pictures.rawFile.type
        : params.data[fileKey].rawFile.type;
    await Storage.put(key, file, { contentType });
    const result = await Storage.get(key);
    [params.data[resultKey]] = result.split('?');
};
const multipleUpload = async ({
    params,
    resource,
    fileKey,
    resultKey,
    idKey,
    idValue,
}) => {
    let files = params.data[fileKey];
    if (!files) return;
    files = files.map(async (file, index) => {
        if (!file) return null;
        if (validImageFormats.includes(file.type)) {
            const error = await checkImageFile(file);
            if (error) return;
        }
        const itemKey =
            idKey && file[idKey]
                ? file[idKey]
                : idValue || new Date().getTime();
        const key = `${resource}/${itemKey}${`_${index}_${uuidv4()}.${file[
            resultKey
        ].rawFile.name
            .split('.')
            .pop()}`}`;
        await Storage.put(key, file[resultKey].rawFile, {
            contentType: file[resultKey].rawFile.type,
            cacheControl: 'max-age=365000000,immutable',
        });
        const result = await Storage.get(key);
        [params.data[fileKey][index][resultKey]] = result.split('?');
        return result;
    });
    await Promise.all(files);
};
const singleDelete = async ({ params, resource, idKey, idValue, index }) => {
    let itemKey =
        idKey && params.previousData[idKey]
            ? params.previousData[idKey]
            : idValue;
    if (typeof itemKey === 'string' && itemKey.includes('https://')) {
        itemKey = decodeURIComponent(itemKey.split('/').pop());
    }
    const key = `${resource}/${itemKey}${
        index !== undefined ? `_${index}` : ''
    }`;
    Storage.remove(key)
        .then(() => {})
        .catch((err) => console.log(err));
};
const multipleDelete = async ({ params, fileKey, ...args }) => {
    if (!params.previousData) return;
    const files = params.previousData[fileKey];
    files.map((file, index) =>
        singleDelete({
            ...args,
            params,
            index,
        })
    );
};
const multipleUpdate = async ({ params, resource, fileKey, resultKey }) => {
    const files = params.previousData[fileKey];
    let newFiles = params.data[fileKey];
    const newFilesPath = newFiles.map((x) => x[resultKey]);
    const removeFiles = files
        .filter((x) => !newFilesPath.includes(x[resultKey]))
        .map(async (file) => {
            const filePath = file[resultKey];
            if (typeof filePath === 'string' || filePath instanceof String) {
                const itemKey = decodeURIComponent(filePath.split('/').pop());
                const key = `${resource}/${itemKey}`;
                const removed = await Storage.remove(key);
                return removed;
            }
            return null;
        });
    await Promise.all(removeFiles);
    const totNew = newFiles.length;
    newFiles = newFiles
        .filter((x) => x && x[resultKey] && x[resultKey].rawFile)
        .map(async (file, index) => {
            if (!file) {
                return null;
            }
            const itemKey = params.data.id;
            const key = `${resource}/${itemKey}${`_${
                totNew + index
            }_${uuidv4()}.${file[resultKey].rawFile.name.split('.').pop()}`}`;
            if (
                file[resultKey] &&
                file[resultKey].rawFile &&
                file[resultKey].rawFile.type
            ) {
                await Storage.put(key, file[resultKey].rawFile, {
                    contentType: file[resultKey].rawFile.type,
                });
            }
            const result = await Storage.get(key);
            const item = params.data[fileKey].find(
                (x) => x[resultKey] === file[resultKey]
            );
            [item[resultKey]] = result.split('?');
            return result;
        });
    await Promise.all(newFiles);
    return params;
};
const upload = async ({
    multiple = false,
    type,
    steps,
    singleUploadOfDifferentFiles,
    ...args
}) => {
    if (type === 'GET_LIST' || type === 'GET_ONE') return null;
    if (args.resource === 'mock-demo') {
        args.resource += '/draft';
    }
    if (steps) {
        await upload({
            type,
            ...steps,
            params: args.params,
            resource: args.resource,
        });
    }
    if (
        singleUploadOfDifferentFiles &&
        singleUploadOfDifferentFiles.length > 0
    ) {
        switch (type) {
            case 'CREATE':
                return Promise.all(
                    singleUploadOfDifferentFiles.map(async (id) => {
                        if (args.params.data[id]) {
                            await singleUpload({
                                params: args.params,
                                resource: args.resource,
                                fileKey: id,
                                resultKey: id,
                            });
                        }
                    })
                );
            case 'DELETE':
                return Promise.all(
                    singleUploadOfDifferentFiles.map(async (id) => {
                        if (args.params.previousData[id]) {
                            await singleDelete({
                                params: args.params,
                                resource: args.resource,
                                idKey: id,
                            });
                        }
                    })
                );
            case 'UPDATE':
                return Promise.all(
                    singleUploadOfDifferentFiles.map(async (id) => {
                        if (
                            args.params.data[id] !==
                            args.params.previousData[id]
                        ) {
                            try {
                                await singleDelete({
                                    params: args.params,
                                    resource: args.resource,
                                    idKey: id,
                                });
                            } catch (e) {
                                console.error('error deleting img', e);
                            }
                            await singleUpload({
                                params: args.params,
                                resource: args.resource,
                                fileKey: id,
                                resultKey: id,
                            }).catch((e) => {
                                console.error('error uploading file', e);
                            });
                        }
                    })
                );
            default:
                return null;
        }
    }
    if (type === 'CREATE')
        return multiple ? multipleUpload(args) : singleUpload(args);
    if (type === 'DELETE')
        return multiple ? multipleDelete(args) : singleDelete(args);
    if (type === 'UPDATE') {
        if (multiple) {
            return multipleUpdate(args);
        }
        const { params, fileKey } = args;
        if (!params.data[fileKey]) {
            return null;
        }
        await singleDelete(args);
        return singleUpload(args);
    }
    if (type === 'DELETE_MANY') {
        if (multiple) {
            args.params.ids.forEach((id) =>
                multipleDelete({ ...args, idValue: id, idKey: undefined })
            );
        } else {
            args.params.ids.forEach((id) =>
                singleDelete({ ...args, idValue: id, idKey: undefined })
            );
        }
    }
    return null;
};
export { upload, singleUpload, multipleUpload, singleDelete, multipleDelete };
