import React, { useCallback, useState } from 'react';
import {
    ImageInput as DefaultImageInput,
    useNotify,
    FileField,
} from 'react-admin';
import {
    validImageFormats,
    MAX_IMAGE_KB,
    checkImageFile,
    checkJsonFile,
} from '../utils/fileUtils';
import Lottie from 'lottie-react';
const LABEL = `Image (max: ${MAX_IMAGE_KB}kb, ${validImageFormats.join(',')})`;
const ImageInput = ({ onChange, children, ...props }) => {
    const notify = useNotify();
    const [validFiles, setValidFiles] = useState([]);
    const [animationData, setAnimationData] = useState(null);
    const handleJsonFile = async (file) => {
        const error = await checkJsonFile(file);
        if (error) {
            notify(error);
            setValidFiles([]);
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const json = JSON.parse(e.target.result);
                setAnimationData(json);
            } catch (err) {
                console.error('Error parsing JSON:', err);
            }
        };
        reader.onerror = () => console.error('Error reading file');
        reader.readAsText(file);
    };
    const handleImageFile = async (file) => {
        if (!file) return;
        const error = await checkImageFile(file);
        if (error) {
            notify(error);
            setValidFiles([]);
            return;
        }
        setValidFiles([file]);
        if (onChange) onChange(file);
    };
    const onChangeHandler = async (event) => {
        const file = event?.rawFile || event;
        if (!file) return;
        file.type === 'application/json'
            ? await handleJsonFile(file)
            : await handleImageFile(file);
    };
    const renderChildren = useCallback(() => {
        if (animationData)
            return <Lottie animationData={animationData} loop={true} />;
        if (validFiles.length) return children;
        return <FileField source="src" title="" />; // Placeholder
    }, [animationData, children, validFiles.length]);
    return (
        <DefaultImageInput
            {...props}
            label={LABEL}
            accept={validImageFormats.join(',')}
            onChange={onChangeHandler}
        >
            {renderChildren()}
        </DefaultImageInput>
    );
};
export default ImageInput;
